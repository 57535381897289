<template>
  <v-card>
    <v-card-title>
      <span class="headline">Planejamento Estratégico</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <span>Introdução</span>
                <editor v-model="Item.texto" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Missão</span>
                <editor v-model="Item.missao" />
              </v-col>
              <v-col>
                <span>Visão</span>
                <editor v-model="Item.visao" />
              </v-col>
              <v-col>
                <span>Valores</span>
                <editor v-model="Item.valores" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :loading="saving" color="darken-1" text @click="save"
        >Salvar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { PlanejamentoEstrategico } from "@/api/eqpdti";
import Editor from "@/components/Editor";
export default {
  name: "PlanejamentoEstrategico",

  components: {
    Editor,
  },
  data: () => ({
    data: [],
    Item: {},
    saving: false,
    tableLoading: false,
    errors: [],
  }),
  methods: {
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    save() {
      this.saving = true;
      let _Item = this.Item;
      _Item.texto = JSON.stringify(_Item.texto);
      _Item.missao = JSON.stringify(_Item.missao);
      _Item.visao = JSON.stringify(_Item.visao);
      _Item.valores = JSON.stringify(_Item.valores);
      const _then = () => {
        this.saving = false;
        this.fetch();
        this.$emitter.emit("alert:success", "Alterações salvas com sucesso");
      };
      const _catch = (error) => {
        this.saving = false;

        this.errors = error.response.data;
        if (this.errors["non_field_errors"]) {
          this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
        } else {
          this.$emitter.emit("alert:error", this.errors);
        }
      };
      PlanejamentoEstrategico.post(_Item).then(_then).catch(_catch);
    },

    fetch() {
      this.tableLoading = true;
      PlanejamentoEstrategico.list().then((response) => {
        let texto = "";
        let missao = "";
        let visao = "";
        let valores = "";
        let item = response.data;
        try {
          texto = JSON.parse(item.texto);
        } catch {
          texto = item.texto;
        } finally {
          item.texto = texto;
        }
        try {
          missao = JSON.parse(item.missao);
        } catch {
          missao = item.missao;
        } finally {
          item.missao = missao;
        }
        try {
          visao = JSON.parse(item.visao);
        } catch {
          visao = item.visao;
        } finally {
          item.visao = visao;
        }
        try {
          valores = JSON.parse(item.valores);
        } catch {
          valores = item.valores;
        } finally {
          item.valores = valores;
        }
        this.Item = item;
        this.tableLoading = false;
      });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style lang="scss">
.ck-content {
  height: 300px;
}
</style>
