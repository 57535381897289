<template>
  <div>
    <div class="editor">
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
        <div class="menubar">
          <div class="toolbar">
            <button class="menubar__button" @click="commands.undo">
              <v-icon> mdi-undo-variant </v-icon>
            </button>

            <button class="menubar__button" @click="commands.redo">
              <v-icon> mdi-redo-variant </v-icon>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <v-icon> mdi-format-bold </v-icon>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <v-icon> mdi-format-italic </v-icon>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.strike() }"
              @click="commands.strike"
            >
              <v-icon> mdi-format-strikethrough </v-icon>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <v-icon> mdi-format-underline </v-icon>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.code() }"
              @click="commands.code"
            >
              <v-icon> mdi-code-tags </v-icon>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.paragraph() }"
              @click="commands.paragraph"
            >
              <v-icon> mdi-format-paragraph </v-icon>
            </button>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <button v-bind="attrs" v-on="on" class="menubar__button">
                  <v-icon> mdi-format-header-equal </v-icon>
                </button>
              </template>
              <v-list>
                <v-list-item v-for="i in [1, 2, 3, 4, 5]" :key="i">
                  <v-list-item-title>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: i }) }"
                      @click="commands.heading({ level: i })"
                    >
                      H{{ i }}
                    </button>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list"
            >
              <v-icon> mdi-format-list-bulleted </v-icon>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.ordered_list() }"
              @click="commands.ordered_list"
            >
              <v-icon> mdi-format-list-numbered </v-icon>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.blockquote() }"
              @click="commands.blockquote"
            >
              <v-icon> mdi-format-quote-open </v-icon>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.code_block() }"
              @click="commands.code_block"
            >
              <v-icon> mdi-code-tags </v-icon>
            </button>

            <button
              class="menubar__button"
              @click="
                commands.createTable({
                  rowsCount: 3,
                  colsCount: 3,
                  withHeaderRow: true,
                })
              "
            >
              <v-icon> mdi-table </v-icon>
            </button>

            <span v-if="isActive.table()">
              <button class="menubar__button" @click="commands.deleteTable">
                <v-icon> mdi-table-remove </v-icon>
              </button>
              <button class="menubar__button" @click="commands.addColumnBefore">
                <v-icon> mdi-table-column-plus-before </v-icon>
              </button>
              <button class="menubar__button" @click="commands.addColumnAfter">
                <v-icon> mdi-table-column-plus-after </v-icon>
              </button>
              <button class="menubar__button" @click="commands.deleteColumn">
                <v-icon> mdi-table-column-remove </v-icon>
              </button>
              <button class="menubar__button" @click="commands.addRowBefore">
                <v-icon> mdi-table-row-plus-before </v-icon>
              </button>
              <button class="menubar__button" @click="commands.addRowAfter">
                <v-icon> mdi-table-row-plus-after </v-icon>
              </button>
              <button class="menubar__button" @click="commands.deleteRow">
                <v-icon> mdi-table-row-remove </v-icon>
              </button>
              <button class="menubar__button" @click="commands.toggleCellMerge">
                <v-icon> mdi-table-merge-cells </v-icon>
              </button>
            </span>
          </div>
        </div>
      </editor-menu-bar>

      <editor-content class="editor__content" :editor="editor" />
    </div>
    <div class="suggestion-list" v-show="showSuggestions" ref="suggestions">
      <template v-if="hasResults">
        <div
          v-for="(table, index) in filteredTables"
          :key="table.id"
          class="suggestion-list__item"
          :class="{ 'is-selected': navigatedTableIndex === index }"
          @click="selectTable(table)"
        >
          {{ table.name }}
        </div>
      </template>
      <div v-else class="suggestion-list__item is-empty">
        Nenhuma tabela encontrada
      </div>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Code,
  Italic,
  Link,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Strike,
  Underline,
  History,
  Mention,
} from "tiptap-extensions";
import Fuse from "fuse.js";
import tippy, { sticky } from "tippy.js";
export default {
  name: "Editor",
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: ["value"],
  data() {
    return {
      query: null,
      suggestionRange: null,
      filteredTables: [],
      navigatedTableIndex: 0,
      insertMention: () => {},
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3, 4, 5] }),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: true,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new Mention({
            items: async () => {
              await new Promise((resolve) => {
                setTimeout(resolve, 500);
              });
              return [
                {
                  id: "planejamento_orcamentario",
                  name: "Tabela - Planejamento Orçamentário",
                },
                {
                  id: "inventario_necessidades",
                  name: "Tabela - Inventário de Necessidades",
                },
                { id: "servidores_tic", name: "Tabela - Servidores de TIC" },
                {
                  id: "documentos_referencia",
                  name: "Tabela - Documentos de Referência",
                },
                {
                  id: "principios_diretrizes",
                  name: "Tabela - Principios e Diretrizes",
                },
                {
                  id: "objetivos_estrategicos",
                  name: "Tabela - Objetivos Estratégicos",
                },
                { id: "matriz_swot", name: "Tabela - Matriz SWOT" },
              ];
            },
            onEnter: ({ items, query, range, command, virtualNode }) => {
              this.query = query;
              this.filteredTables = items;
              this.suggestionRange = range;
              this.renderPopup(virtualNode);
              // we save the command for inserting a selected mention
              // this allows us to call it inside of our custom popup
              // via keyboard navigation and on click
              this.insertMention = command;
            },
            // is called when a suggestion has changed
            onChange: ({ items, query, range, virtualNode }) => {
              this.query = query;
              this.filteredTables = items;
              this.suggestionRange = range;
              this.navigatedTableIndex = 0;
              this.renderPopup(virtualNode);
            },
            // is called when a suggestion is cancelled
            onExit: () => {
              // reset all saved values
              this.query = null;
              this.filteredTables = [];
              this.suggestionRange = null;
              this.navigatedTableIndex = 0;
              this.destroyPopup();
            },
            // is called on every keyDown event while a suggestion is active
            onKeyDown: ({ event }) => {
              if (event.key === "ArrowUp") {
                this.upHandler();
                return true;
              }
              if (event.key === "ArrowDown") {
                this.downHandler();
                return true;
              }
              if (event.key === "Enter") {
                this.enterHandler();
                return true;
              }
              return false;
            },
            // is called when a suggestion has changed
            // this function is optional because there is basic filtering built-in
            // you can overwrite it if you prefer your own filtering
            // in this example we use fuse.js with support for fuzzy search
            onFilter: async (items, query) => {
              if (!query) {
                return items;
              }
              await new Promise((resolve) => {
                setTimeout(resolve, 500);
              });
              const fuse = new Fuse(items, {
                threshold: 0.2,
                keys: ["name"],
              });
              return fuse.search(query).map((item) => item.item);
            },
          }),
        ],
        content: this.value,
        onUpdate: this.onUpdate.bind(this),
      }),
      emitAfterOnUpdate: false,
    };
  },
  computed: {
    hasResults() {
      return this.filteredTables.length;
    },
    showSuggestions() {
      return this.query || this.hasResults;
    },
  },
  watch: {
    value(val) {
      if (this.emitAfterOnUpdate) {
        this.emitAfterOnUpdate = false;
        return;
      }
      if (this.editor) {
        this.editor.setContent(val);
      }
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    onUpdate(info) {
      this.emitAfterOnUpdate = true;
      this.$emit("input", info.getJSON(), info);
    },
    upHandler() {
      this.navigatedTableIndex =
        (this.navigatedTableIndex + this.filteredTables.length - 1) %
        this.filteredTables.length;
    },
    // navigate to the next item
    // if it's the last item, navigate to the first one
    downHandler() {
      this.navigatedTableIndex =
        (this.navigatedTableIndex + 1) % this.filteredTables.length;
    },
    enterHandler() {
      const table = this.filteredTables[this.navigatedTableIndex];
      if (table) {
        this.selectTable(table);
      }
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    selectTable(table) {
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: table.id,
          label: table.name,
        },
      });
      this.editor.focus();
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderPopup(node) {
      const boundingClientRect = node.getBoundingClientRect();
      const { x, y } = boundingClientRect;
      if (x === 0 && y === 0) {
        return;
      }
      if (this.popup) {
        return;
      }
      // ref: https://atomiks.github.io/tippyjs/v6/all-props/
      this.popup = tippy(".editor", {
        getReferenceClientRect: () => boundingClientRect,
        appendTo: () => document.body,
        interactive: true,
        sticky: true, // make sure position of tippy is updated when content changes
        plugins: [sticky],
        content: this.$refs.suggestions,
        trigger: "mouseenter", // manual
        showOnCreate: true,
        theme: "dark",
        placement: "top-start",
        inertia: true,
        duration: [400, 200],
      });
    },
    destroyPopup() {
      if (this.popup) {
        this.popup[0].destroy();
        this.popup = null;
      }
    },
  },
};
</script>

<style lang="scss">
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #dddddd;

.editor {
  position: relative;
  max-width: 300rem;
  margin: 0 auto 5rem auto;

  &__content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    * {
      caret-color: currentColor;
    }

    pre {
      padding: 0.7rem 1rem;
      border-radius: 5px;
      background: $color-black;
      color: $color-white;
      font-size: 0.8rem;
      overflow-x: auto;

      code {
        display: block;
      }
    }

    p code {
      padding: 0.2rem 0.4rem;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      background: rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
    }

    ul,
    ol {
      padding-left: 1rem;
    }

    li > p,
    li > ol,
    li > ul {
      margin: 0;
    }

    a {
      color: inherit;
    }

    blockquote {
      border-left: 3px solid rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
      padding-left: 0.8rem;
      font-style: italic;

      p {
        margin: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 3px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 2px solid $color-grey;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: 0;
        width: 4px;
        z-index: 20;
        background-color: #adf;
        pointer-events: none;
      }
    }

    .tableWrapper {
      margin: 1em 0;
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  }
}

.menubar {
  margin-bottom: 1rem;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-black;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-black, 0.05);
    }

    &.is-active {
      background-color: rgba($color-black, 0.1);
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }
}

.menububble {
  position: absolute;
  display: flex;
  z-index: 9000;
  background: $color-black;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba($color-white, 0.1);
    }

    &.is-active {
      background-color: rgba($color-white, 0.2);
    }
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__input {
    font: inherit;
    border: none;
    background: transparent;
    color: $color-white;
  }
}

.mention {
  background: rgba($color-black, 0.1);
  color: rgba($color-black, 0.6);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}
.mention-suggestion {
  color: rgba($color-black, 0.6);
}
.suggestion-list {
  padding: 0.2rem;
  border: 2px solid rgba($color-black, 0.1);
  font-size: 0.8rem;
  font-weight: bold;
  &__no-results {
    padding: 0.2rem 0.5rem;
  }
  &__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &.is-selected,
    &:hover {
      background-color: rgba($color-white, 0.2);
    }
    &.is-empty {
      opacity: 0.5;
    }
  }
}
.tippy-box[data-theme~="dark"] {
  background-color: $color-black;
  padding: 0;
  font-size: 1rem;
  text-align: inherit;
  color: $color-white;
  border-radius: 5px;
}
</style>
